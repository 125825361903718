import { ZodError } from 'zod';
import {
  LOCALE_COOKIE_KEY,
  QUERY_PARAM_LOCALE_KEY,
  i18n_PATH_ATTRIBUTE,
  i18n_TARGET_ATTRIBUTE,
  i18n_VALUES_ATTRIBUTE,
} from '../../constants';
import type { NestedKeyOf } from '../../types';
import { setMetadataForLocale } from '../scripts/helpers/seo';
import cs from './cs';
import en from './en';

export const i18n = {
  defaultLocale: 'cs',
  locales: ['en', 'cs'],
} as const;

export type Locale = (typeof i18n)['locales'][number];

/**
 * Checks if a given locale is valid.
 * @param locale - The locale to check.
 * @returns True if the locale is valid, false otherwise.
 */
export function isValidLocale(locale: string | Locale): boolean {
  return i18n.locales.includes(locale as Locale);
}

/**
 * Retrieves the current locale based on the value stored in the cookie.
 * If no valid locale is found in the cookie, the default locale is returned.
 * @returns The current locale.
 */
export function currentLocale(): Locale {
  try {
    let locale: Locale = i18n.defaultLocale;
    const queryLocale = new URL(window.location.href).searchParams.get(QUERY_PARAM_LOCALE_KEY);

    // Go through all cookies and find the one with the locale key
    document.cookie.split(';')?.find(cookie => {
      const [key, value] = cookie?.trim().split('=');

      if (key === LOCALE_COOKIE_KEY && value) {
        // If the value is a valid locale, set it
        if (isValidLocale(value)) {
          locale = value as Locale;
        }
      }
    });

    if (queryLocale && locale !== queryLocale) {
      locale = queryLocale as Locale;
    }

    return locale;
  } catch (err) {
    console.error(`Error: ${err}`);
    return i18n.defaultLocale;
  }
}

function getHostName(hostname: string) {
  const parts = hostname.split('.');

  if (parts.length === 1) {
    return hostname;
  } else {
    return `.${hostname.split('.').slice(1).join('.')}`;
  }
}

/**
 * Sets the locale for the application.
 * @param locale The locale to set.
 */
export function setLocale(locale: Locale): void {
  if (isValidLocale(locale)) {
    document.cookie = `${LOCALE_COOKIE_KEY}=${locale};path=/;SameSite=Lax;Domain=${getHostName(
      window.location.hostname,
    )};Secure`;
    document.documentElement.lang = locale;

    const url = new URL(window.location.href);
    url.searchParams.set(QUERY_PARAM_LOCALE_KEY, locale);
    window.history.pushState({}, '', url);
  }
}

/**
 * Translate the given scope path
 */
export function t(scope: LocaleScopePath, values?: Record<string, string | number | bigint>) {
  const locale = currentLocale();
  switch (locale) {
    case 'cs':
      const translationCs = path(cs, scope) as string;
      return setValuesForT(translationCs ?? scope, values);
    case 'en':
      const transactionEn = path(en, scope) as string;
      return setValuesForT(transactionEn ?? scope, values);
    default:
      console.error(`Error: Locale ${locale} is not supported, falling back to ${i18n.defaultLocale}`);
      return path(cs, scope) as string;
  }
}

const setValuesForT = (translation: string, values?: Record<string, string | number | bigint>) => {
  if (values) {
    Object.entries(values).forEach(([key, value]) => {
      translation = translation.replace(`#{${key}}`, String(value));
    });
  }

  return translation;
};

/**
 * Return the scope path with type safety
 */
export function _t(scope: LocaleScopePath) {
  return scope;
}

/**
 * Go through all elements with the data-i18n attribute and translate them
 *  @see `data-i18n` attribute must contain the path to the translation (@see `LocaleScopePath` type and `t()` function) )
 *  @see `i18n-target` attribute can be used to specify which element property to translate. Default is `innerText`.
 *
 * This function also sets the metadata for the current locale.
 */
export function translateHtml() {
  const nodes = Array.from(document.querySelectorAll(`[${i18n_PATH_ATTRIBUTE}]`));

  nodes.forEach(node => {
    const i18nValues = node.attributes.getNamedItem(i18n_VALUES_ATTRIBUTE)?.value;
    const i18nScope = node.getAttribute(i18n_PATH_ATTRIBUTE) as LocaleScopePath;
    const i18nTarget = (node.attributes.getNamedItem(i18n_TARGET_ATTRIBUTE)?.value ?? 'innerText') as
      | keyof ARIAMixin
      | 'innerHTML';

    if (i18nScope) {
      try {
        let translation = t(i18nScope, i18nValues ? JSON.parse(i18nValues) : undefined);

        // Replace #{year} with the current year for footer scope path to copyright.
        if (i18nScope === 'pages.common.footer.copy') {
          translation = translation.replace('#{year}', String(new Date().getFullYear()));
        }

        node[i18nTarget] = translation;
      } catch (e) {
        console.error(`Error: ${e}`);
      }
    }
  });

  // Set metada for current locale
  setMetadataForLocale();

  // Update passed query params

  const anchorLinks = Array.from(document.querySelectorAll('a[href]'));
  anchorLinks.forEach(element => {
    const href = (element as HTMLAnchorElement)?.href;
    if (href?.includes(QUERY_PARAM_LOCALE_KEY)) {
      const newUrl = new URL(href);
      newUrl.searchParams.set(QUERY_PARAM_LOCALE_KEY, currentLocale());
      element.setAttribute('href', newUrl.toString());
    }
  });

  const formsWithActions = Array.from(document.querySelectorAll('form[action]'));
  formsWithActions.forEach(element => {
    const href = (element as HTMLFormElement)?.action;
    if (href?.includes(QUERY_PARAM_LOCALE_KEY)) {
      const newUrl = new URL(href, document.location.href);
      newUrl.searchParams.set(QUERY_PARAM_LOCALE_KEY, currentLocale());
      element.setAttribute('action', newUrl.pathname + newUrl.search);
    }
  });
}

export type LocaleScopePath = NestedKeyOf<typeof import('./en').default>;

/**
 * Get nested object property
 * @param obj
 * @param path
 * @param fallback
 */
export function path<T extends Record<string, any>>(obj: T, path: string, fallback?: string) {
  let i = 0;
  let len = 0;
  const pathArr = path.split('.');

  for (i = 0, pathArr, len = pathArr.length; i < len; i++) {
    if (!obj || typeof obj !== 'object') return fallback;
    obj = obj[pathArr[i]];
  }

  if (obj === undefined) return fallback;
  return obj;
}

export function displayValidationErrors(form: HTMLFormElement, zodError: ZodError, onlyForPaths?: string[]) {
  const errors = zodError.flatten(issue => {
    return { ...issue, rootPath: issue.path[0] };
  });
  // console.log(errors);

  // Clear previous errors
  clearValidationErrors(form);

  Object.entries(errors.fieldErrors)
    // Filter out errors that are not specified in the `onlyForPaths` array
    .filter(([path, _]) => {
      if (!onlyForPaths?.length) {
        return true;
      } else {
        return onlyForPaths?.includes(path);
      }
    })
    .forEach(([path, errors], i) => {
      // console.log(path, errors);

      errors?.forEach((error, index) => {
        const describedById = `error-${path}_${i}_:${index}:`;
        const relatedFormItem = form.querySelector(`[name="${path}"]`);

        if (relatedFormItem) {
          relatedFormItem.setAttribute('aria-invalid', 'true');
          relatedFormItem.setAttribute('aria-describedby', describedById);

          const formGroup = relatedFormItem.closest('div.form-group');

          if (formGroup) {
            formGroup.setAttribute('data-invalid', 'true');

            let value: number | string | bigint = '';
            if (error.code === 'too_small') {
              value = error.minimum;
            }
            if (error.code === 'too_big') {
              value = error.maximum;
            }

            if (error.code === 'custom') {
              value = error.params?.maximum || error.params?.minimum || undefined;
            }

            const errorNode = document.createElement('span');
            errorNode.textContent = t(error.message as LocaleScopePath, { value });
            errorNode.id = describedById;
            errorNode.setAttribute(i18n_PATH_ATTRIBUTE, error.message);
            if (value) {
              errorNode.setAttribute(i18n_VALUES_ATTRIBUTE, JSON.stringify({ value }));
            }
            errorNode.className = 'field-error';

            formGroup.appendChild(errorNode);
          }
        }
      });
    });
}

/**
 * Clears the validation errors for a given form.
 *
 * @param form - The HTML form element to clear validation errors for.
 */
export function clearValidationErrors(form: HTMLFormElement) {
  const formGroups = form.querySelectorAll('div.form-group');
  formGroups.forEach(formGroup => {
    formGroup.removeAttribute('data-invalid');
    formGroup.removeAttribute('aria-describedby');

    const errorNodes = formGroup.querySelectorAll('.field-error');
    errorNodes.forEach(e => e.remove());
  });
}
